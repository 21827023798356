import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCPGVtKREN8BrVOpRCWVjxtqYpH1hli8YI",
    authDomain: "cgroofpainting.firebaseapp.com",
    projectId: "cgroofpainting",
    storageBucket: "cgroofpainting.appspot.com",
    messagingSenderId: "75023433875",
    appId: "1:75023433875:web:6a841f81758ca898b58d66",
    measurementId: "G-LC42DP93SN"
};

const app = initializeApp(firebaseConfig);

export { app };